import "./App.css";
import React, { useEffect, useState } from "react";
import logo from "./img/logo.png";
// import styles
import "lightgallery/css/lightgallery.css";
import "lightgallery/css/lg-video.css";
// import plugins if you need
import { request } from "graphql-request";
import ProjectTabButton from "./components/projectTabButtons";
import ProjectItem from "./components/projectItem";

function App() {
  const [data, setData] = useState([]);
  const [activeTab, setActiveTab] = useState(0);

  const query = `
     {
      categories {
        id
        catName
        projects {
          title
          videoId
        }
      }
    }
  `;

  useEffect(() => {
    request(
      "https://api-ap-south-1.graphcms.com/v2/cl0qcyx7t9xfl01xkg9e972bd/master",
      query
    ).then((result) => { 
      setData(result.categories);
    });
  },[]);

  const showActiveTabContent = (tabId) => {
    setActiveTab(tabId);
  };


  return (
    <>
      <div>
        <div className="main-wrapper">
          <div className="ting-logo">
            <img src={logo} />
          </div>
          <div className="tab">
            <div className="button_section">
              <h5>
                {  
                  data && (
                  <ProjectTabButton data={data} showActiveTabContent={showActiveTabContent}/>
                  )
                }
              </h5>
            </div>
          </div>
        </div>
      </div>
      <div className="logo-section">
          {
           data &&
            data.map((currElem, id) => (
              <div
                className={
                  id == activeTab ? "categories active" : "categories"
                }
                id={`tab_${id}`}
                key={id}
              >
                <div className="logo-section">
                  {
                    currElem.projects.map((project, id) => (
                      <ProjectItem project={project} key={id} />
                  ))}
                </div>
              </div>
            )) 
          }
      </div>
    </>
  );
}

export default App;
