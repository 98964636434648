import LightGallery from "lightgallery/react";
// import styles
import "lightgallery/css/lightgallery.css";
import "lightgallery/css/lg-video.css";

import lgVideo from "lightgallery/plugins/video";

const ProjectItem = ({ project }) => {
  return (
    <div className="logo-inside">
      <LightGallery plugins={[lgVideo]}>
        <a
          data-src={`https://www.youtube.com/watch?v=${project.videoId}`}
          target="_blank"
        >
          <div className="logo-img">
            <div className="overlay"></div>
            <img
              className="img-responsive"
              src={`https://img.youtube.com/vi/${project.videoId}/mqdefault.jpg`}
              alt=""
            />
          </div>
          <p>{project.title}</p>
        </a>
      </LightGallery>
    </div>
  );
};

export default ProjectItem;
